@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-pagination-item:not(.ant-pagination-item-active),
.ant-pagination-item-link {
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.ant-pagination-item,
.ant-pagination-item-link {
  border-radius: 3px !important;
}

.ant-tabs-nav {
  width: 100% !important;
  }
  
.ant-tabs-tab {
  display: block;
  flex: 1;
  text-align: center;
  justify-content: center;
}
  
.ant-tabs-nav > div:nth-of-type(1) {
  display: unset !important;
  width: 100% !important;
}
.ant-table-row {
  cursor: pointer;
}

/* rainbowkit modal layout style override */
.iekbcc0.ju367v8a.ju367v6j.ju367v74.ju367v7p {
  max-height: 600px !important;
  overflow-y: auto;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.token-logo-wrap {
  background-color: rgb(41, 50, 73);
  color: rgb(255, 255, 255);
  border-radius: 100%;
  line-height: 36px;
  text-align: center;
  height: 100%;
  width: 35px;
}